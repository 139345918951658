<template>
    <div>
        <red-header :event-name="event.getName()" :event-url="event.getAnketaUrl()"/>

        <registration-belarus
                v-if="currentStep === 'registration-belarus'"
                v-bind:city.sync="city"
                v-bind:phone.sync="phone"
                v-bind:error-message.sync="wrongPhoneError"
                :mark-city-required="markCityRequired"
                :mark-phone-required="markPhoneRequired"
                :has-required-fields-missing="registrationComponentHasRequiredFieldsMissing"
                v-on:go-forward="validateRegistrationAndGo"
        />

        <select-user-type
                v-if="currentStep === 'select-user-type'"
                v-model="type"
                v-on:go-forward="validateUserTypeAndGo"
                v-on:go-back="currentStep = 'registration-belarus'"
                :participants="event.getParticipants()"
        />

        <keep-alive>
            <component
                    v-if="currentStep === 'user-type'"
                    :is="type"
                    :event="event"
                    :disable-go-forward="waitingResponse"
                    v-on:go-back="currentStep = 'select-user-type'"
                    v-bind:api-data.sync="userTypeComponentApiData"
                    v-on:ready-to-go="sendDataAndGoodbye"
            />
        </keep-alive>

        <last-step-offline
                v-if="currentStep === 'last-step-offline'"
                :sms-code="consumerSmsCode"
                :key="lastStepComponentKey"
        />
        <last-step-sms-verification
                v-if="currentStep === 'last-step-sms-verification'"
                :phone-number="phone"
                :sms-code="consumerSmsCode"
                :consumer-id="consumerId"
                :key="lastStepComponentKey"
                v-on:sms-confirmed="currentStep = 'last-step-goodbye'"
        />
        <last-step-sms-re-verification
                v-if="currentStep === 'last-step-sms-re-verification'"
                :consumer-id="consumerId"
                :key="lastStepComponentKey"
                v-on:sms-sent="currentStep = 'last-step-sms-verification'"
        />
        <last-step-goodbye v-if="currentStep === 'last-step-goodbye'"/>

        <user-has-been-registered v-if="currentStep === 'user-has-been-registered'"/>
    </div>
</template>

<script>
import RedHeader from "@/components/RedHeader.vue";
import Registration from "@/components/steps/belarus/Registration.vue";
import Event from "@/models/event";

const StepOneSelectUserType = () =>
    import(
        /* webpackChunkName: "belaurus-step-one-select-user-type" */
        "@/components/steps/belarus/anketa/StepOneSelectUserType.vue"
        );
const Owner = () =>
    import(
        /* webpackChunkName: "belaurus-owner" */
        "@/components/steps/belarus/anketa/owner/Owner.vue"
        );
const Breeder = () =>
    import(
        /* webpackChunkName: "belaurus-breeder" */
        "@/components/steps/belarus/anketa/breeder/Breeder.vue"
        );
const Doctor = () =>
    import(
        /* webpackChunkName: "belaurus-doctor" */
        "@/components/steps/belarus/anketa/doctor/Doctor.vue"
        );
const Seller = () =>
    import(
        /* webpackChunkName: "belaurus-seller" */
        "@/components/steps/belarus/anketa/seller/Seller.vue"
        );
const UserHasBeenRegistered = () =>
    import(
        /* webpackChunkName: "user-has-been-registered" */
        "@/components/steps/UserHasBeenRegistered.vue"
        );
const LastStepSmsVerification = () =>
    import(
        /* webpackChunkName: "last-step-sms-verification" */
        "@/components/steps/LastStepSmsVerification.vue"
        );
const LastStepSmsReVerification = () =>
    import(
        /* webpackChunkName: "last-step-sms-re-verification" */
        "@/components/steps/LastStepSmsReVerification.vue"
        );
const LastStepOffline = () =>
    import(
        /* webpackChunkName: "last-step-offline" */
        "@/components/steps/LastStepOffline.vue"
        );
const LastStepGoodbye = () =>
    import(
        /* webpackChunkName: "last-step-goodbye" */
        "@/components/steps/LastStepGoodbye.vue"
        );

export default {
    components: {
        RedHeader,
        "registration-belarus": Registration,
        "select-user-type": StepOneSelectUserType,
        Owner,
        Breeder,
        Doctor,
        Seller,
        LastStepSmsVerification,
        LastStepSmsReVerification,
        LastStepOffline,
        LastStepGoodbye,
        UserHasBeenRegistered,
    },

    props: {
        event: {
            type: Event,
            required: true,
        },
    },

    data() {
        return {
            currentStep: "registration-belarus",
            city: null,
            phone: "",
            type: "owner",
            markCityRequired: false,
            markPhoneRequired: false,
            userTypeComponentApiData: {},
            waitingResponse: false, // Ожидаем ответ от API
            phoneNumberIsUnique: true,
            consumerSmsCode: "",
            consumerId: "",
            lastStepComponentKey: 0,
            ip: null,
            wrongPhoneError: "",
        };
    },

    created() {
        this.fetchIp();
    },

    watch: {
        city() {
            this.markCityRequired = !this.cityIsValid;
        },
        phone() {
            this.markPhoneRequired = !this.phoneIsValid;
        },
    },

    computed: {
        apiData() {
            return {
                ...{
                    event_id: this.event.getId(),
                    country: "Беларусь",
                    phone: this.phone,
                    consumer_type: this.type,
                    city: this.cityApiData,
                    ip: this.ip,
                },
                ...this.userTypeComponentApiData,
            };
        },

        cityApiData() {
            return this.cityIsValid
                ? {
                    city_name: this.city.getName(),
                    city_path: this.city.getPath(),
                    city_soato: this.city.getSoatoCode(),
                }
                : {};
        },

        cityIsValid() {
            return this.city != null;
        },

        phoneIsValid() {
            return this.phone.length === 13;
        },

        registrationComponentIsValid() {
            return this.cityIsValid && this.phoneIsValid;
        },

        registrationComponentHasRequiredFieldsMissing() {
            return this.markCityRequired || this.markPhoneRequired;
        },
    },

    methods: {
        fetchIp() {
            this.$http
                .get("https://api.ipify.org", {
                    withCredentials: false,
                    headers: {},
                })
                .then((res) => (this.ip = res.data));
        },

        validateRegistrationAndGo() {
            this.markCityRequired = !this.cityIsValid;
            this.markPhoneRequired = !this.phoneIsValid;

            if (this.registrationComponentIsValid) {
                this.checkRegistrationPhone();
            }
        },

        validateUserTypeAndGo() {
            this.currentStep = "user-type";
        },

        sendDataAndGoodbye() {
            this.postQuestionary();
        },

        postQuestionary() {
            this.waitingResponse = true;
            this.$http
                .post("/api/v1/consumers", this.apiData, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${process.env.VUE_APP_TEMPORARY_TOKEN}`,
                    },
                })
                .then(this.handlePostQuestionaryResponse)
                .catch(this.handlePostQuestionaryError)
                .finally(() => (this.waitingResponse = false));
        },

        handlePostQuestionaryResponse(response) {
            this.consumerSmsCode = response.data.data["sms_code"];
            this.consumerId = response.data.data["id"];
            this.currentStep = this.event.isOnline() ? "last-step-sms-verification" : "last-step-offline";
            this.rerenderLastStep();
        },

        handlePostQuestionaryError() {
            console.log("Произошла ошибка при создании анкеты.");
        },

        rerenderLastStep() {
            this.lastStepComponentKey += 1;
        },

        checkRegistrationPhone() {
            this.waitingResponse = true;
            this.$http
                .get(`/api/v1/events/${this.event.getId()}/consumer/?phone=${this.phone}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${process.env.VUE_APP_TEMPORARY_TOKEN}`,
                    },
                })
                .then(this.handleCheckRegistrationPhoneResponse)
                .catch(this.handleCheckRegistrationPhoneError)
                .finally(() => (this.waitingResponse = false));
        },

        // Здесь оказываемся, когда найден уже пользователь с таким телефоном
        handleCheckRegistrationPhoneResponse(response) {
            this.consumerSmsCode = String(response.data["sms_code"]);
            this.consumerId = String(response.data["id"]);
            let isConfirmed = Boolean(response.data["is_confirmed"]);

            if (isConfirmed) {
                this.currentStep = "user-has-been-registered";
                return;
            }

            this.currentStep = this.event.isOnline() ? "last-step-sms-re-verification" : "last-step-offline";
            this.phoneNumberIsUnique = false;
        },

        handleCheckRegistrationPhoneError(error) {
            if (error.response) {
                // Это самый правильный вариант ответа, так как не должно быть ошибок и регистраций с таким телефоном
                if (error.response.status === 404) {
                    this.phoneNumberIsUnique = true;
                    this.currentStep = "select-user-type";
                    return;
                }
            }

            if (error.response.status === 422) {
                if ("message" in error.response.data) {
                    this.wrongPhoneError = error.response.data.message;
                    return;
                }
            }

            // В случае всех остальных ошибок мы не пускаем дальше так как дальше лучше не будет
            console.log("Произошла ошибка при проверке телефона пользователя.");
        },
    },
};
</script>
