<template>
    <!-- Красная шапка с картинкой -->
    <div class="flex flex-col bg-local bg-top bg-no-repeat" style="background-image: url('/images/background.jpg')">
        <!-- Логотип -->
        <div class="flex w-full h-32 justify-center mb-6" style="margin-top: 280px">
            <div class="flex w-32 h-32 rounded-full bg-white justify-center items-center shadow-2xl pl-1">
                <a :href="eventUrl"><img class="w-24" src="@/assets/images/logo.png" alt="logo" /></a>
            </div>
        </div>

        <div v-if="hasEvent">
            <!-- Заголовок -->
            <div class="flex flex-col text-white leading-tight text-center">
                <div class="flex flex-col text-3xl font-semibold">
                    <slot name="header"></slot>
                </div>
                <span class="text-2xl py-3" v-text="event.getName()"></span>
            </div>

            <!-- Прыгающая стрелка -->
            <div class="flex justify-center items-center py-4" v-if="hasBouncingArrow">
                <svg
                    class="w-8 h-8 text-white stroke-current animate-bounce"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M19 14L12 21M12 21L5 14M12 21L12 3"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import Event from "@/models/event";

export default {
    props: {
        event: {
            type: Event,
            default: null,
        },
        hasBouncingArrow: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasEvent() {
            return this.event !== null;
        },

        eventUrl() {
            return this.hasEvent ? this.event.getAnketaUrl() : "/";
        },
    },
};
</script>
